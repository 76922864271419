<template>
  <div class="container">
    <table style="height: 70vh;  margin-top: 100px; ">
    <tbody>

      <iframe width="540" height="505" src="https://27282f54.sibforms.com/serve/MUIFAHgG110GdUxhoaJx3Ebdm4KgOB4m8pSc4_Fa54HTpEIlOpVju8oZAHqdLZyj_ahgiC-IC-VX9ybsVyShzY1xQnc-VrlCQfEQgatDbZpTglGTkYYC2TVMyoeF0E88R_0dRaMNApHuGmSd-dNoIt43jqU3KvpDyUFLDrhmwds1ibO-5i7I0599teBxkycXdslU_ByC3vPj8Pkr" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
     
</tbody>
</table>
</div>
<br>
<br>
<br>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p{
  padding:0;
  margin:0;
}
</style>
